import React, { Component } from 'react'

export default class Blog extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-blog" data-section="blog">
			<div className="colorlib-narrow-content">
				<div className="row">
					<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
					<span className="heading-meta">Read</span>
					<h2 className="colorlib-heading">Recent Blog</h2>
					</div>
				</div>
				<div className="row">
					{/* <div className="col-md-4 col-sm-6 animate-box" data-animate-effect="fadeInLeft">
					<div className="blog-entry">
						<a target="_blank" href="https://medium.com/@tdwivedi2708/building-a-simple-disk-backed-key-value-store-in-go-7ac06f70ad28" className="blog-img"><img src="images/blogs/KVstore_Golang.png" className="img-responsive" alt="HTML5 Bootstrap Template by colorlib.com" /></a>
						<div className="desc">
							<span><small>Oct 28, 2024 </small> | <small> Database Development </small> | <small> <i className="icon-bubble3" /> 4</small></span>
							<h3>''
								<a target="_blank" href="">
								</a>
							</h3>
							<p>This article guides you through building KeyValor, a hash-based, disk-backed key-value store in Go, with code directly from the project.</p>
						</div>
					</div>
					</div> */}
					<BlogCard 
						blog_heading="Building a Simple Disk-Backed Key-Value Store in Go"
						blog_url="https://medium.com/@tdwivedi2708/building-a-simple-disk-backed-key-value-store-in-go-7ac06f70ad28"
						blog_img_url="images/blogs/KVstore_Golang.png"
						category="Database Development"
						date="Oct 28, 2024"
						description="This article guides you through building KeyValor, a hash-based, disk-backed key-value store in Go, with code directly from the project."
					></BlogCard>
				</div>
				{/* <div className="row">
					<div className="col-md-12 animate-box">
					<p><a href="#" className="btn btn-primary btn-lg btn-load-more">Load more <i className="icon-reload" /></a></p>
					</div>
				</div> */}
			</div>
			</section>
      </div>
    )
  }
}

function BlogCard({blog_heading, blog_url, blog_img_url, category, date, description}){
	return (
	<div className="col-md-4 col-sm-6 animate-box" data-animate-effect="fadeInLeft">
		<div className="blog-entry">
			<a target="_blank" href={blog_url} className="blog-img"><img src={blog_img_url} className="img-responsive" alt="HTML5 Bootstrap Template by colorlib.com" /></a>
			<div className="desc">
				<span><small> {date} </small> | <small> {category} </small> </span>
				<h3>
					<a target="_blank" href={blog_url}> {blog_heading}
					</a>
				</h3>
				<p>{description}</p>
			</div>
		</div>
	</div>
	)
}